/**
 * Utility functions
 */

import { ROLES } from "@/const";

/**
 * Retrieve the standard redirect based on the given role.
 * 
 * If role is null, redirects to /login
 * 
 * @param {string} role 
 * @returns 
 */
const getHomeForRole = function (role) {
    if (!role) {
        return "/login"
    }
    if (role === ROLES.MANAGER) {
        return "/ingest"
    }
    // Default case
    return "/publish"
}

/**
 * Returns the default toast body.
 * 
 * If an error is given, the error message is returned.
 * 
 * @param {*} err The response object given in an error.
 * @returns 
 */
const defaultToastBody = function (err = null) {
    if (!err) {
        return "Unexpected error occurred."
    }
    let body = `${err.data.message}`
    // if (err.status === 401) {  // Unauthorized = bad login
    //     body += "...???..."
    // }
    return body
}

/**
 * Returns a new object with standard toast config.
 * 
 * If an error is given, the error status is shown in the title.
 * 
 * @param {*} err The response object given in an error.
 * @returns A config to use for toasting.
 */
const defaultToastConfig = (err = null) => {
    let _conf = {
        title: "Unhandled exception",
        autoHideDelay: 7500,
        solid: true,
        toaster: 'b-toaster-bottom-right',
        variant: "danger",
    }
    if (!err) {
        return _conf
    }
    _conf.title = `${err.statusText} (HTTP/${err.status})`
    return _conf
}

const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const formatDateAsStr = (date) => {
    let day = date.getDate()
    if (day < 10) {
        day = '0' + day
    }
    // Month is 0-indexed. getDate is not - JavaScript is a sane language
    let month = date.getMonth() + 1
    if (month < 10) {
        month = '0' + month
    }
    return date.getFullYear() + "-" + month + "-" + day
}

export {
    getHomeForRole,
    defaultToastBody,
    defaultToastConfig,
    sleep,
    formatDateAsStr
}