<template>
  <b-container class="home">
    <b-row>
      <b-col cols="12" lg="10" offset-lg="1">
        <h3>Publishing files for new Version(s)</h3>
        <b-form-select
          class="mb-1"
          v-model="category"
          :options="categoryOptions"
          :state="categoryIsValid"
        ></b-form-select>
        <!-- Chrome can't handle dropping files (webkitrelativepath doesn't get populated) -->
        <b-file
          webkitrelativedirectory
          v-if="this.files.length == 0"
          class="big-file-input"
          placeholder="Browse to the submission root directory."
          drop-placeholder="Drop submission root..."
          no-traverse
          no-drop
          multiple
          directory
          v-model="files"
          @input="processFileInput"
        >
          <template slot="file-name" slot-scope="{ files }">{{ files.length}} files selected</template>
        </b-file>
        <!-- <b-list-group
          v-if="this.files.length >0"
          @dragenter.prevent
          @dragover.prevent
          @drop.prevent="dropCSV"
        >-->
        <!-- <b-list-group-item v-for="(file) in this.fakeFileList" :key="file.name"> -->
        <!-- <b-badge variant="dark">{{ file.name }}</b-badge> -->
        <!-- {{ file.name }} -->
        <!-- <b-button>DEL</b-button> -->
        <!-- <b-progress :value="progressValue" :max="maxProgress" show-progress animated></b-progress> -->
        <!-- </b-list-group-item>
        </b-list-group>-->
        <div
          ref="csvDiv"
          :class="{csvDrop: this.csvHover}"
          @dragover.prevent
          @drop.prevent="dropCSV"
        >
          <b-table
            striped
            v-if="this.files.length > 0"
            :fields="fields"
            :items="tableVersionItemsList"
          >
            <!-- <template #cell(description)="row">
              <b-form-input type="text" v-model="row.item.description"></b-form-input>
            </template>-->
          </b-table>
        </div>
        <b-button class="mx-1 my-1" @click="clearFiles">Clear Files</b-button>
        <b-button
          class="mx-1 my-1"
          @click="submitFiles"
          variant="primary"
          :disabled="!submitIsValid"
        >Submit Files</b-button>
      </b-col>
    </b-row>
    <b-modal
      ref="submitModal"
      title="Publishing files"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <b-list-group>
        <b-list-group-item
          v-for="stage in submitInfo.stages"
          :key="stage.index"
          class="flex-column justify-content-between"
        >
          <b-icon v-if="stage.state == STAGE_STATES.WAITING" icon="arrow-clockwise"></b-icon>
          <b-icon
            v-if="stage.state == STAGE_STATES.IN_PROGRESS"
            icon="arrow-clockwise"
            variant="primary"
            animation="spin"
          ></b-icon>
          <b-icon v-if="stage.state == STAGE_STATES.FINISHED" icon="check-circle" variant="success"></b-icon>
          <b-icon v-if="stage.state == STAGE_STATES.ERROR" icon="x-circle" variant="danger"></b-icon>
          {{stage.display_text}}
          <b-progress :max="stage.progressTotal" v-if="stage.progressTotal" show-progress>
            <b-progress-bar :value="stage.progress">
              <span>
                <strong>{{ stage.progress }} / {{ stage.progressTotal }}</strong>
              </span>
            </b-progress-bar>
            <b-progress-bar :value="stage.progressErrors" variant="warning">
              <span>
                <strong>{{ stage.progressErrors }} / {{ stage.progressTotal }}</strong>
              </span>
            </b-progress-bar>
          </b-progress>
        </b-list-group-item>
      </b-list-group>
      <!-- <p>{{submitMessage}}</p> -->
      <!-- <div v-if="submitStage == STAGE.FINISHED">
        <h3>Result</h3>
        <p>Found 14 Style definitions. 6 Style definitions (and their tasks) were added. 8 Were already known.</p>
      </div>-->
      <b-button
        variant="success"
        block
        @click="closeModal"
        :disabled="!this.submitInfo.finished"
      >Close</b-button>
    </b-modal>
  </b-container>
</template>

<style lang="scss">
// Custom SCSS rules from this GH issue (Bigger drop zone for file uploads):
// https://github.com/bootstrap-vue/bootstrap-vue/issues/4570#issuecomment-573729870
$file-height: 100px;

/* Might need adjusting depending on the chosen height */
$padding-percent: 0.4;

.big-file-input {
  height: $file-height;
}

.big-file-input > label {
  text-align: center;
  height: $file-height;

  /* Add a percent of height as padding-top for content */
  padding-top: $file-height * $padding-percent;
}

// This sets height and padding for the "Browse" button
.big-file-input > label::after {
  height: $file-height - 2px;
  padding-top: ($file-height - 2px) * $padding-percent;
}

.csvDrop {
  border-color: blue;
  border-width: 1px;
  border-style: dashed;
}
</style>

<script>
import api from "@/backendapi";
import { defaultToastBody, defaultToastConfig } from "@/util";

const IMAGE_TYPES = ["png", "jpg", "jpeg", "tga", "tif", "tiff", "exr", "bmp"];
const MOVIE_TYPES = ["mov", "mp4", "mkv"];

const _STAGE_STATES = {
  WAITING: 10,
  IN_PROGRESS: 20,
  FINISHED: 30,
  ERROR: -100
};

const _resetSubmitInfo = () => {
  return {
    finished: false,
    resultText: "",
    resultLines: [],
    stages: {
      PUB_REQUEST: {
        index: 0,
        state: _STAGE_STATES.WAITING,
        display_text: "Request publish (validation)"
      },
      PUB_CREATE_SG: {
        index: 1,
        state: _STAGE_STATES.WAITING,
        display_text: "Create SG entries"
      },
      PUB_UPLOAD: {
        index: 2,
        state: _STAGE_STATES.WAITING,
        display_text: "Upload file(s)",
        progress: 0,
        progressTotal: 1
      },
      PUB_THUMBNAIL: {
        index: 3,
        state: _STAGE_STATES.WAITING,
        display_text: "Upload thumbnail(s)",
        progress: 0,
        progressTotal: 1,
        progressErrors: 0
      },
      PUB_FINALIZE: {
        index: 4,
        state: _STAGE_STATES.WAITING,
        display_text: "Finalize version(s)"
      }
    }
  };
};

export default {
  name: "Publish",
  mounted() {
    // Set class on CSV drop div based on drag/drop action.
    this.$refs.csvDiv.addEventListener("dragover", () => {
      this.csvHover = true;
    });
    this.$refs.csvDiv.addEventListener("drop", () => {
      this.csvHover = false;
    });
    this.$refs.csvDiv.addEventListener("dragleave", () => {
      this.csvHover = false;
    });
  },
  data: function() {
    return {
      csvHover: false,
      STAGE_STATES: _STAGE_STATES,
      submitInfo: _resetSubmitInfo(),
      fields: [
        {
          key: "version_dir",
          label: "Version root"
        },
        {
          key: "metadata.description",
          label: "Description"
        },
        {
          key: "num_files",
          label: "Number of files"
        },
        {
          key: "metadata_ok",
          label: "Metadata OK?"
        },
        {
          key: "thumbnail_ok",
          label: "Thumbnail OK?"
        }
      ],
      files: [],
      category: null,
      versionItems: {}
    };
  },
  watch: {
    categoryOptions: function() {
      if (this.categoryOptions.length == 2) {
        this.category = this.categoryOptions[1].value;
      }
    }
  },
  computed: {
    categoryOptions: function() {
      return this.$store.state.categoryOptions;
    },
    fileMap: function() {
      let _result = {};
      this.files.forEach(f => {
        let fpath = f.webkitRelativePath ? f.webkitRelativePath : f.name;
        let fparts = fpath.split("/");
        if (fparts.length <= 2) {
          _result[fparts[fparts.length - 1]] = f;
        } else {
          // Strip off root + version dir; allow subdirs in keys
          fpath = fparts.slice(2).join("/"); // return with subdir
          _result[fpath] = f;
        }
      });
      // this.files.forEach(f => {
      //   _result[f.name] = f;
      // });
      return _result;
    },
    user: function() {
      return this.$store.state.user;
    },
    tableVersionItemsList: function() {
      let vals = Object.values(this.versionItems);
      let copies = vals.map(v => {
        return Object.assign({}, v);
      });
      copies.forEach(v => {
        if (!v.metadata_ok) {
          v._rowVariant = "danger";
        }
      });
      return copies;
    },
    categoryIsValid() {
      if (this.category == null) {
        return null;
      }
      return this.category ? true : false;
    },
    submitIsValid() {
      if (Object.keys(this.versionItems).length === 0) {
        return false;
      }
      for (let vi of Object.values(this.versionItems)) {
        if (!vi.metadata_ok) {
          return false;
        }
      }
      if (!this.category) {
        return false;
      }
      return true;
    }
    // fileList: function() {
    //   // Flat file list
    //   return this.files.map(f => {
    //     return f.webkitRelativePath.length > 0 ? f.webkitRelativePath : f.name;
    //   });
    // }
  },
  methods: {
    validateFiles(files) {
      // TODO: Validation here
      console.log(files);
      return files.length >= 0;
    },
    // flattenFiles(files) {
    //   /* NOTE: While bootstrap-vue has a no-traverse option. This does
    //      not work for Chrome file drops... */
    //   console.log(files);
    //   files.forEach(f => {
    //     if (Array.isArray(f)) {
    //       return this.flattenFiles(f);
    //     }
    //     let wke = f.webkitGetAsEntry();
    //     console.log(wke);
    //   });
    //   let _flist = [];
    //   return _flist
    // },
    commonPrefix(filePaths) {
      // Shamelessly copied from https://stackoverflow.com/a/68703218
      // check border cases size 1 array and empty first path)
      if (!filePaths[0] || filePaths.length == 1) return filePaths[0] || "";
      let i = 0;
      // while all paths have the same character at position i, increment i
      while (filePaths[0][i] && filePaths.every(w => w[i] === filePaths[0][i]))
        i++;

      // prefix is the substring from the beginning to the last successfully checked i
      return filePaths[0].substr(0, i);
    },
    processFiles(files) {
      let verItems = {};
      let csvFile = "";
      let prefix = this.commonPrefix(
        files.map(f => (f.webkitRelativePath ? f.webkitRelativePath : f.name))
      );
      console.log(`Submission prefix: ${prefix}`);
      files.forEach(f => {
        let fpath = f.webkitRelativePath ? f.webkitRelativePath : f.name;
        fpath = fpath.slice(prefix.length);
        let fparts = fpath.split("/");
        if (fparts.length <= 1) {
          // file is directly under submission root
          // Ignore file unless it's metadata.
          if (fparts[fparts.length - 1] == "metadata.csv") {
            csvFile = f;
          }
          return;
        }
        // If any part of the file path starts with a ".", ignore the file.
        let hasHidden = fparts.some(fpart => fpart.startsWith("."));
        if (hasHidden) {
          return;
        }
        let ver_root = fparts[0]; // object key
        if (!(ver_root in verItems)) {
          this.$set(verItems, ver_root, this._makeVersionEntry(ver_root));
          //verItems[ver_root] = this._makeVersionEntry(ver_root);
        }
        verItems[ver_root].files.push(f);
        verItems[ver_root].num_files += 1;
      });
      this.versionItems = verItems;
      if (csvFile) {
        this.processCSV(csvFile);
      }
    },
    _makeVersionEntry(ver_root) {
      return {
        version_dir: ver_root,
        description: "",
        files: [],
        num_files: 0,
        metadata: {},
        metadata_ok: false,
        thumbnail_ok: false
      };
    },
    processFileInput(files) {
      this.validateFiles(files);
      this.processFiles(files);
    },
    truncateName(fname) {
      if (fname.length > 50) {
        return "toolong";
      }
      return fname;
    },
    dropCSV(evt) {
      console.log("CSV drop");
      console.log(evt);
      let toastConfig = defaultToastConfig();
      toastConfig.title = "Separate CSV drop not implemented";
      toastConfig.variant = "warning";
      this.$bvToast.toast(
        `CSV currently is only detected along with the inital submission drop. It must be named "metadata.csv" in the submission root.`,
        toastConfig
      );

      // TODO: Set information
    },
    processCSV(csvFile) {
      this.readCSV(csvFile, this.processCSVCallback);
    },
    readCSV(csvFile, callback) {
      const reader = new FileReader();
      reader.onload = function(evt) {
        // Copied almost verbatim from https://sebhastian.com/javascript-csv-to-array/
        const csvString = evt.target.result;
        let headers = csvString.slice(0, csvString.indexOf("\n")).split(",");
        headers = headers.map(val => val.replace(/[\n\r]+/g, ""));
        const rows = csvString.slice(csvString.indexOf("\n") + 1).split("\n");
        const arr = rows.map(function(row) {
          let values = row.split(",");
          // Remove strange characters
          values = values.map(val => val.replace(/[\n\r]+/g, ""));
          const el = headers.reduce(function(object, header, index) {
            object[header] = values[index];
            return object;
          }, {});
          return el;
        });
        // Fix type
        arr.forEach(row => {
          row.version = parseInt(row.version);
        });
        callback(arr);
      };
      reader.readAsText(csvFile);
    },
    processCSVCallback(csvRowsData) {
      console.log("csv data:");
      console.log(csvRowsData);
      csvRowsData.forEach(row => {
        if (!row.ian && !row.wavepid) {
          // Ignore empty entries (last \n creates bad entries)
          return;
        }
        let unmatchCount = 0;
        if (row.folderpath in this.versionItems) {
          let versionItem = this.versionItems[row.folderpath];
          Object.assign(versionItem.metadata, row);
          versionItem.metadata.thumbnailpath = this._getThumbnailFilename(
            versionItem
          );
          if (versionItem.metadata.thumbnailpath) {
            if (versionItem.metadata.thumbnailpath in this.fileMap) {
              this.$set(versionItem, "thumbnail_ok", true);
            } else {
              // NOTE: Split from below for more accurate warning message
              this.$set(versionItem, "thumbnail_ok", false); // explicit to be sure
              let toastConfig = defaultToastConfig();
              toastConfig.title = "Unrecognized thumbnail path";
              toastConfig.variant = "warning";
              this.$bvToast.toast(
                `Could not find thumbnail file specified in metadata ("${versionItem.metadata.thumbnailpath}").
                Is it entered correctly? (Case sensitive!)`,
                toastConfig
              );
            }
          } else {
            // NOTE: Split from above for more accurate warning message
            this.$set(versionItem, "thumbnail_ok", false); // explicit to be sure
            let toastConfig = defaultToastConfig();
            toastConfig.title = "Missing thumbnail";
            toastConfig.variant = "warning";
            this.$bvToast.toast(
              `Could not find thumbnail file for Version in "${versionItem.version_dir}".`,
              toastConfig
            );
          }
          // TODO: Should do a metadata check (ie. check each expected field is defined)
          this.$set(versionItem, "metadata_ok", true);
          //versionItem.metadata_ok = true;
        } else {
          console.log("row has no match");
          // TODO: Error or warning here?
          unmatchCount++;
        }
        if (unmatchCount) {
          let toastConfig = defaultToastConfig();
          toastConfig.title = `Warning: metadata may be incorrect`;
          toastConfig.variant = "warning";
          this.$bvToast.toast(
            `Metadata contained ${unmatchCount} entries that could not be linked to version folders!`,
            toastConfig
          );
        }
      });
    },
    clearFiles() {
      this.files = [];
      this.versionItems = {};
    },
    removeLastFile() {
      this.files.pop();
    },
    async submitFiles() {
      // Setup
      this.submitInfo = _resetSubmitInfo();
      this.submitInfo.stages.PUB_UPLOAD.progress = 0;
      let numFilesToUpload = 0;
      Object.values(this.versionItems).forEach(vi => {
        numFilesToUpload += vi.files.length;
      });
      this.submitInfo.stages.PUB_UPLOAD.progressTotal = numFilesToUpload;
      this.submitInfo.stages.PUB_THUMBNAIL.progress = 0;
      this.submitInfo.stages.PUB_THUMBNAIL.progressTotal = Object.keys(
        this.versionItems
      ).length;
      this.$store.commit("setLeaveGuard", true);
      this.$refs["submitModal"].show();
      try {
        // Stage 1: Publish validation
        await this._publishValidate();
        // Stage 2: Publish start (SG entries created)
        let sgVersions = await this._publishStart();
        // Stage 3: File & thumbnail uploading
        await this._publishUpload(sgVersions);
        // Stage 4: Finalize upload
        await this._publishFinalize(sgVersions);
      } catch (err) {
        console.log(err);
        // TODO: Set each unfinished stage to error
        // for each failed stage: this.submitInfo.stages.STAGEHERE.state = _STAGE_STATES.ERROR;
        let resp = err.response;
        this.$bvToast.toast(defaultToastBody(resp), defaultToastConfig(resp));
      } finally {
        this.submitInfo.finished = true;
        this.$store.commit("setLeaveGuard", false); // release leaveGuard
      }
    },
    async _publishValidate() {
      this.submitInfo.stages.PUB_REQUEST.state = _STAGE_STATES.IN_PROGRESS;
      try {
        await api.postValidatePublish(
          localStorage.token,
          this.category,
          this.versionItems
        );
        this.submitInfo.stages.PUB_REQUEST.state = _STAGE_STATES.FINISHED;
      } catch (err) {
        this.submitInfo.stages.PUB_REQUEST.state = _STAGE_STATES.ERROR;
        throw err;
      }
    },
    /**
     * Return object structure is:
     *
     * {<versionid>: {
     *   <filename>: {
     *     fields: {...}, url: "..."}
     *   },
     *   ...
     * ...}
     */
    async _publishStart() {
      this.submitInfo.stages.PUB_CREATE_SG.state = _STAGE_STATES.IN_PROGRESS;
      try {
        let response = await api.postSubmitPublish(
          localStorage.token,
          this.category,
          this.versionItems
        );
        this.submitInfo.stages.PUB_CREATE_SG.state = _STAGE_STATES.FINISHED;
        return response.versions;
      } catch (err) {
        this.submitInfo.stages.PUB_CREATE_SG.state = _STAGE_STATES.ERROR;
        throw err;
      }
    },
    async _publishUpload(sgVersions) {
      let uploadFilesPromise = this._publishUploadFiles(sgVersions);
      let uploadThumbnailsPromise = this._publishUploadThumbnails(sgVersions);
      // Wait for both promises to finish
      await uploadFilesPromise;
      await uploadThumbnailsPromise;
      if (this.submitInfo.stages.PUB_THUMBNAIL.progressErrors) {
        let toastConfig = defaultToastConfig();
        toastConfig.title = "Missing thumbnail(s)";
        toastConfig.variant = "warning";
        this.$bvToast.toast(
          `${this.submitInfo.stages.PUB_THUMBNAIL.progressErrors} new Version(s) submitted without a thumbnail.`,
          toastConfig
        );
      }
    },
    async _publishUploadFiles(sgVersions) {
      // -- S3 PRESINGED URL (generic)
      // Object.values(sgVersions).forEach(async fileSpec => {
      //   for (const [fname, s3Url] of Object.entries(fileSpec)) {
      //     let fileObject = this.fileMap[fname];
      //     let requestConfig = {}; // TODO: track upload progress?
      //     await api.putS3Object(fileObject, s3Url, requestConfig);
      //     this.submitInfo.stages.PUB_UPLOAD.progress += 1;
      //   }
      // });
      // -- S3 PRESIGNED POST
      this.submitInfo.stages.PUB_UPLOAD.state = _STAGE_STATES.IN_PROGRESS;
      let fileSpecifications = Object.values(sgVersions).map(
        sgVer => sgVer.s3_files
      );
      try {
        // TODO: Should be fileSpecifications.forEach? Object.values seems to work with Array though
        let fileSpecs = Object.values(fileSpecifications);
        for (const fileSpec of fileSpecs) {
          for (const [fname, s3Data] of Object.entries(fileSpec)) {
            let fileObject = this.fileMap[fname];
            let requestConfig = {}; // TODO: track upload progress?
            await api.postS3Object(
              fileObject,
              s3Data.url,
              s3Data.fields,
              requestConfig
            );
            this.submitInfo.stages.PUB_UPLOAD.progress += 1;
          }
        }
        this.submitInfo.stages.PUB_UPLOAD.state = _STAGE_STATES.FINISHED;
      } catch (err) {
        this.submitInfo.stages.PUB_UPLOAD.state = _STAGE_STATES.ERROR;
        throw err;
      }
    },
    async _publishUploadThumbnails(sgVersions) {
      this.submitInfo.stages.PUB_THUMBNAIL.state = _STAGE_STATES.IN_PROGRESS;
      try {
        for (const [sgVerID, sgVerData] of Object.entries(sgVersions)) {
          if (!sgVerData.metadata.thumbnailpath) {
            console.log(
              "Version has no thumbnail, skipping thumbnail publish."
            );
            this.submitInfo.stages.PUB_THUMBNAIL.progressErrors += 1;
            continue;
          }
          let formData = new FormData();
          // let file = this.files[0];
          if (!(sgVerData.metadata.thumbnailpath in this.fileMap)) {
            console.log("Unrecognized thumb path. Skipping...");
            this.submitInfo.stages.PUB_THUMBNAIL.progressErrors += 1;
            continue;
          }
          let _file = this.fileMap[sgVerData.metadata.thumbnailpath];
          // TODO: Error handling missing file here?
          formData.append("thumbnail", _file, _file.name);
          let requestConfig = {
            // SEE ABOVE UPLOAD HANDLING
            // onUploadProgress: progEvt => {
            //   this.submitInfo.stages.UPLOAD.progress =
            //     (progEvt.loaded / progEvt.total) * 100;
            //   if (this.submitInfo.stages.UPLOAD.progress >= 99.999999) {
            //     this.submitInfo.stages.UPLOAD.state = _STAGE_STATES.FINISHED;
            //   }
          };
          try {
            await api.postSubmitThumbnail(
              localStorage.token,
              sgVerData.metadata.category,
              sgVerID,
              formData,
              requestConfig
            );
            console.log("increment thumbs");
            this.submitInfo.stages.PUB_THUMBNAIL.progress += 1;
          } catch (err) {
            console.log(err);
            throw err;
          }
        }
        //
        //
      } catch (err) {
        this.submitInfo.stages.PUB_THUMBNAIL.state = _STAGE_STATES.ERROR;
        throw err;
      }
      this.submitInfo.stages.PUB_THUMBNAIL.state = _STAGE_STATES.FINISHED;
    },
    _getThumbnailFilename(versionData) {
      let versionMetadata = versionData.metadata;
      let thumbpath = versionMetadata.thumbnailpath;
      if (thumbpath) {
        return thumbpath;
      } else {
        return this._findThumbnailFilename(versionData);
      }
    },
    _findThumbnailFilename(versionData) {
      const _filenames = versionData.files.map(f => f.name);
      for (let _f of _filenames) {
        let ext = _f.split(".").pop();
        if (MOVIE_TYPES.includes(ext)) {
          return _f;
        }
      }
      for (let _f of _filenames) {
        let ext = _f.split(".").pop();
        if (IMAGE_TYPES.includes(ext)) {
          return _f;
        }
      }
      return "";
    },
    async _publishFinalize(sgVersions) {
      this.submitInfo.stages.PUB_FINALIZE.state = _STAGE_STATES.IN_PROGRESS;
      // TODO implement

      await api.postFinalizePublish(
        localStorage.token,
        this.category,
        sgVersions // TODO: Should this be only the IDs?
      );
      this.submitInfo.stages.PUB_FINALIZE.state = _STAGE_STATES.FINISHED;
    },
    closeModal() {
      this.$refs["submitModal"].hide();
    }
  },
  components: {}
};
</script>
