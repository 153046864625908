/**
 * All API access is provided through these functions.
 */

const axios = require('axios').default;

const API_BASE = process.env.VUE_APP_API_BACKEND

let postLogin = async (payload) => {
    /**
     * Payload tbd (user + pass).
     */
    const resp = await axios.post(
        API_BASE + '/login',
        payload);
    return resp.data  // {token: <token>}
};

let getUsers = async (token) => {
    const resp = await axios.get(
        API_BASE + '/list_users',
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data  // {???}
}


let getSgGroups = async (token) => {
    const resp = await axios.get(
        API_BASE + '/list_groups',
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data  // {???}
}


let postRegister = async (token, payload) => {
    /**
     * Payload =
     */
    const resp = await axios.post(
        API_BASE + '/register',
        payload,
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data
}

let postDeleteUser = async (token, username) => {
    let payload = {
        username: username
    }
    const resp = await axios.post(
        API_BASE + '/unregister',
        payload,
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data
}


let postChangePassword = async (token, payload) => {
    /**
     * payload = {password (current), newPassword}
     */
    const resp = await axios.post(
        API_BASE + '/change_pass',
        payload,
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data
}


let postModifyUser = async (token, payload) => {
    /**
     * payload = {username, password (optional), role (opional)}
     */
    const resp = await axios.post(
        API_BASE + '/manage_users',
        payload,
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data
}

let getProfile = async (token) => {
    const resp = await axios.get(
        API_BASE + '/profile',
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data  // {???}
}

const postValidateExcel = async (token, category, formData, requestConfig) => {
    const resp = await axios.post(
        API_BASE + `/ingest/validate/${category}`,
        formData,
        {
            "headers": {
                'jwt-token': token,
                'Content-Type': 'multipart/form-data'
            },
            ...requestConfig
        }
    );
    return resp.data
}


const postSubmitExcel = async (token, category, formData, requestConfig) => {
    const resp = await axios.post(
        API_BASE + `/ingest/submit/${category}`,
        formData,
        {
            "headers": {
                'jwt-token': token,
                'Content-Type': 'multipart/form-data'
            },
            ...requestConfig
        }
    );
    return resp.data
}


const postValidateGrundImport = async (token, category, formData, requestConfig) => {
    const resp = await axios.post(
        API_BASE + `/foodingest/validate/${category}`,
        formData,
        {
            "headers": {
                'jwt-token': token,
                'Content-Type': 'multipart/form-data'
            },
            ...requestConfig
        }
    );
    return resp.data
}


const postSubmitGrundImport = async (token, category, formData, requestConfig) => {
    const resp = await axios.post(
        API_BASE + `/foodingest/submit/${category}`,
        formData,
        {
            "headers": {
                'jwt-token': token,
                'Content-Type': 'multipart/form-data'
            },
            ...requestConfig
        }
    );
    return resp.data
}

const postValidatePublish = async (token, category, versionItems) => {
    let payloadData = []
    Object.values(versionItems).forEach(versionItem => {
        let versionPayload = {}
        Object.assign(versionPayload, versionItem.metadata)
        versionPayload.files = versionItem.files.map(f => {
            let fpath = f.webkitRelativePath ? f.webkitRelativePath : f.name;
            let fparts = fpath.split("/");
            return fparts[fparts.length - 1]
        })
        payloadData.push(versionPayload)
    })
    let payload = {
        category: category,
        data: payloadData
    }
    const resp = await axios.post(
        API_BASE + `/publish/validate`,
        payload,
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data
}

const postSubmitPublish = async (token, category, versionItems) => {
    let payloadData = []
    Object.values(versionItems).forEach(versionItem => {
        let versionPayload = {}
        Object.assign(versionPayload, versionItem.metadata)
        versionPayload.files = versionItem.files.map(f => {
            let fpath = f.webkitRelativePath ? f.webkitRelativePath : f.name;
            let fparts = fpath.split("/");
            if (fparts.length <= 2) {
                return fparts[fparts.length - 1]  // return fname only
            }
            // Strip off root + version dir; allow subdirs in keys
            return fparts.slice(2).join("/")  // return with subdir
        })
        payloadData.push(versionPayload)
    })
    let payload = {
        category: category,
        data: payloadData
    }
    const resp = await axios.post(
        API_BASE + `/publish/start`,
        payload,
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data
}

const postSubmitThumbnail = async (token, category, versionId, formData, requestConfig) => {
    // /publish/thumbnail/<category>/<int:version_id>
    const resp = await axios.post(
        API_BASE + `/publish/thumbnail/${category}/${versionId}`,
        formData,
        {
            "headers": {
                'jwt-token': token,
                'Content-Type': 'multipart/form-data'
            },
            ...requestConfig
        }
    );
    return resp.data
}

const postFinalizePublish = async (token, category, sgVersionIds) => {
    let versions = Object.keys(sgVersionIds).map(verStr => parseInt(verStr))
    let payload = {
        category: category,
        versions: versions
    }
    const resp = await axios.post(
        API_BASE + `/publish/finalize`,
        payload,
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data
}

const postS3Object = async (fileObj, url, fields, requestConfig) => {
    let formData = new FormData()
    // NOTE: FILE MUST BE SPECIFIED LAST. I WON'T GET THAT HOUR BACK
    //  BUT HOPEFULLY THIS SAVES YOURS
    Object.keys(fields).forEach(key => {
        formData.append(key, fields[key]);
    });
    formData.append("file", fileObj, fileObj.name);
    const resp = await axios.post(
        url,
        formData,
        {
            "headers": {
                'Content-Type': 'multipart/form-data'
            },
            ...requestConfig
        }
    );
    return resp.data
}

// Presigned put
const putS3Object = async (fileObj, url, requestConfig) => {
    const resp = await axios.put(
        url,
        {  // data param
            data: fileObj,
        },
        {  // config param
            ...requestConfig
        }
    );
    return resp.data
}

const getApiVersion = async (token) => {
    try {
        const resp = await axios.get(
            API_BASE + '/api_version',
            {
                "headers": {
                    'jwt-token': token
                }
            }
        );
        return resp.data
    } catch (err) {
        return "1.0.0"
    }
}

const getAllowedProjects = async (token) => {
    const resp = await axios.get(
        API_BASE + '/list_allowed_projects',
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data.project_list
}

const get3DAgencies = async (token) => {
    const resp = await axios.get(
        API_BASE + '/sg/3d_agencies',
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data
}

const getAgencyRules = async (token, payload) => {
    const resp = await axios.get(
        API_BASE + '/agencyassignmentrules',
        {
            "headers": {
                'jwt-token': token
            },
            "params": payload
        }
    );
    return resp.data
}

const postCreateAgencyRule = async (token, payload) => {
    const resp = await axios.post(
        API_BASE + '/agencyassignmentrules/new',
        payload,
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data
}

const postValidateAgencyRule = async (token, payload) => {
    const resp = await axios.post(
        API_BASE + '/agencyassignmentrules/validate',
        payload,
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data
}

const postTestAgencyRule = async (token, payload) => {
    const resp = await axios.post(
        API_BASE + '/agencyassignmentrules/test',
        payload,
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data
}

const postDisableAgencyRule = async (token, ruleId) => {
    const resp = await axios.post(
        API_BASE + `/agencyassignmentrules/disable/${ruleId}`,
        {},  // No payload
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data
}

const postDisableAllAgencyRules = async (token) => {
    const resp = await axios.post(
        API_BASE + '/agencyassignmentrules/disable/all',
        {},  // No payload
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data
}

const postValidateRulesCSV = async (token, formData, requestConfig) => {
    const resp = await axios.post(
        API_BASE + `/agencyassignmentrules/import/validate`,
        formData,
        {
            "headers": {
                'jwt-token': token,
                'Content-Type': 'multipart/form-data'
            },
            ...requestConfig
        }
    );
    return resp.data
}

const postSubmitRulesCSV = async (token, formData, requestConfig) => {
    const resp = await axios.post(
        API_BASE + `/agencyassignmentrules/import/submit`,
        formData,
        {
            "headers": {
                'jwt-token': token,
                'Content-Type': 'multipart/form-data'
            },
            ...requestConfig
        }
    );
    return resp.data
}

const getJasperFields = async (token) => {
    const resp = await axios.get(
        API_BASE + '/jasperfields',
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data
}
const postCreateJasperField = async (token, payload) => {
    const resp = await axios.post(
        API_BASE + '/jasperfields/new',
        payload,
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data
}

const postDeleteJasperField = async (token, fieldId) => {
    const resp = await axios.post(
        API_BASE + `/jasperfields/delete/${fieldId}`,
        {},  // No payload
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data
}

const postSubmitJasperFieldCSV = async (token, formData, requestConfig) => {
    const resp = await axios.post(
        API_BASE + `/jasperfields/import/submit`,
        formData,
        {
            "headers": {
                'jwt-token': token,
                'Content-Type': 'multipart/form-data'
            },
            ...requestConfig
        }
    );
    return resp.data
}


const getConfigKeyValues = async (token, configKey) => {
    const resp = await axios.get(
        API_BASE + '/config/list/' + configKey,
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data
}

const postConfigKeyValues = async (token, configKey, values) => {
    let payload = {
        data: values
    }
    const resp = await axios.post(
        API_BASE + '/config/submit/' + configKey,
        payload,
        {
            "headers": {
                'jwt-token': token
            }
        }
    );
    return resp.data
}

/**
 * Query complexity time offset values from the server.
 */
const getComplexityTimeOffsets = async (token) => {
    const resp = await axios.get(
        API_BASE + '/complexitytimeoffset/list',
        {"headers": {'jwt-token': token}}
    );
    return resp.data  // [{id: int, complexity: str, offset: int}, ...]
};

/**
 * Create a new complexity time offset value and store it on the server.
 * 
 * @param {string} token Authentication token.
 * @param {string} level Complexity level.
 * @param {number} offset Integer representing offset in days.
 * @returns response data
 */
const postComplexityTimeOffset = async (token, level, offset) => {
    let payload = {
        complexity_level: level,
        offset: offset
    }
    const resp = await axios.post(
        API_BASE + '/complexitytimeoffset/new',
        payload,
        {"headers": {'jwt-token': token}}
    );
    return resp.data
}

/**
 * Remove a complexity time offset from the storage server keyed by its id.
 */
const postDeleteComplexityTimeOffset = async (token, id) => {
    const resp = await axios.post(
        API_BASE + `/complexitytimeoffset/delete/${id}`,
        {},
        {"headers": {'jwt-token': token}}
    );
    return resp.data
}

const postValidateValidationMatrix = async (token, formData, requestConfig) => {
    console.log("postEmailAllowlistMatrix called with formData:")
    console.log(formData)
    console.log("reqestConfig:")
    console.log(requestConfig)
    const resp = await axios.post(
        API_BASE + `/validationmatrix/validate`,
        formData,
        {
            "headers": {
                'jwt-token': token,
                'Content-Type': 'multipart/form-data'
            },
            ...requestConfig
        }
    );
    return resp.data
}

const postSubmitValidationMatrix = async (token, formData, requestConfig) => {
    console.log("postEmailAllowlistMatrix called with formData:")
    console.log(formData)
    console.log("reqestConfig:")
    console.log(requestConfig)
    const resp = await axios.post(
        API_BASE + `/validationmatrix/submit`,
        formData,
        {
            "headers": {
                'jwt-token': token,
                'Content-Type': 'multipart/form-data'
            },
            ...requestConfig
        }
    );
    return resp.data
}

const getValidationMatrix = async (token) => {
    const resp = await axios.get(
        API_BASE + '/validationmatrix/fetch',
        {
            responseType: 'blob',
            "headers": {
                'jwt-token': token,
            }
        }
    );
    return resp.data

}

export default {
    postLogin,
    getUsers,
    getSgGroups,
    postRegister,
    postDeleteUser,
    postChangePassword,
    postModifyUser,
    getProfile,
    postValidateExcel,
    postSubmitExcel,
    postValidateGrundImport,
    postSubmitGrundImport,
    postValidatePublish,
    postSubmitPublish,
    postSubmitThumbnail,
    postFinalizePublish,
    postS3Object,
    putS3Object,
    getApiVersion,
    getAllowedProjects,
    get3DAgencies,
    getAgencyRules,
    postCreateAgencyRule,
    postValidateAgencyRule,
    postTestAgencyRule,
    postDisableAgencyRule,
    postDisableAllAgencyRules,
    postValidateRulesCSV,
    postSubmitRulesCSV,
    getJasperFields,
    postCreateJasperField,
    postDeleteJasperField,
    postSubmitJasperFieldCSV,
    getConfigKeyValues,
    postConfigKeyValues,
    getComplexityTimeOffsets,
    postComplexityTimeOffset,
    postDeleteComplexityTimeOffset,
    postValidateValidationMatrix,
    postSubmitValidationMatrix,
    getValidationMatrix
}